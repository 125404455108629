export default {
  methods: {
    copyText(string) {
      if (string == undefined || string == null) return;
      if (typeof string == "number") string = string.toString();
      navigator.clipboard
        .writeText(string.trim())
        .then(() => {
          this.$toast.success(`${string.trim()} copied to clipboard!`, {
            timeout: 2000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Error: copyText()");
        });
    },
  },
};
